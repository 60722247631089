.carousel {
  overflow: hidden;
  white-space: nowrap;
}

.scrolling-wrapper {
  display: flex;
  animation: scroll-linear 30s linear infinite;
}

.logo {
  margin-right: 24px; /* Espaciado entre logos */
  display: inline-flex; /* Alinea los logos en línea */
}

/* Asegúrate de que el keyframe mueve los elementos la distancia correcta */
@keyframes scroll-linear {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}